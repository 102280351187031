<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import DynamicTitle from "../../common/components/DynamicTitle.vue";
import { SuawInputGroup, SuawParagraph } from "@suaw/suaw-component-library";
export default {
  name: "ResourcesOurOrganization",
  components: { ResourceFrame, DynamicTitle, SuawInputGroup, SuawParagraph }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-our-organization-heading">Our <b>Organization</b></h1>
      <SuawParagraph
        class="suaw-our-organization-intro"
        text='Shut Up & Write! is an initiative of <a href="https://writingpartners.org" target="_blank" rel="noopener noreferrer">Writing Partners</a>, a nonprofit organization dedicated to helping writers connect, create, and thrive. Since 2007, Shut Up & Write! has been providing resources and a proven methodology for people to run successful writing groups. We believe bringing people together to write forges connections and combats isolation traditionally felt by writers. Through our community-driven writing events, we help writers help each other.'
      />
      <DynamicTitle :before="['The', 'History', 'of']" size="medium" weight="bold" />
      <SuawParagraph
        text="Founded in 2007 in San Francisco, Shut Up & Write! was created by science fiction writer Rennie Saunders, who recognized that many writers struggle to maintain a consistent writing routine. He launched the first chapter at Crossroads Cafe, committing to a weekly meeting with one simple rule: Shut Up and Write! This straightforward approach quickly resonated with writers looking for support and accountability."
      />
      <SuawParagraph
        text="What began with a handful of members quickly took off. After a successful first event, one eager participant suggested starting a second chapter, and before long, Shut Up & Write! had expanded to several cafes across San Francisco. The first East Coast event followed when a member relocated to New York City, setting off a global movement."
      />
      <SuawParagraph
        text="Fast forward to today, and we're proud to boast over 112,000 members in 414 cities worldwide. Our events still revolve around Rennie's proven formula: brief introductions, focused writing time, and a final check-in to share progress. When COVID-19 hit, we pivoted to online gatherings, proving that our method works just as well through video chat. Now, we offer both in-person and online events to fit every schedule and lifestyle."
      />
      <SuawParagraph
        text="Shut Up & Write! has also made waves in academia, with professors and students hosting events at universities to tackle research projects and dissertations. Our method has become so popular that it's even the subject of academic research."
      />
      <SuawParagraph
        text="As we look ahead, our mission remains clear: to foster a fun, supportive environment for writers everywhere. Whether you're an experienced author or just starting out, come join us at Shut Up & Write!"
      />
      <h2 class="suaw-our-organization-subtitle">
        What We're About
      </h2>
      <div class="suaw-our-organization-guide">
        <ul class="suaw-our-organization-guide__list">
          <li class="suaw-our-organization-guide__item">
            <SuawParagraph text="<b>Empowering Writers:</b> We give writers the tools, know-how, and support they need to help each other succeed." />
          </li>
          <li class="suaw-our-organization-guide__item">
            <SuawParagraph
              text="<b>Intentional Connection:</b> We believe that bringing people together to engage in creative activities generates positivity, productivity, and friendship."
            />
          </li>
          <li class="suaw-our-organization-guide__item">
            <SuawParagraph
              text="<b>Community-Powered Support:</b> We're all about writers supporting writers. Our community is where inspiration strikes, ideas flow, and connections form that change lives."
            />
          </li>
          <li class="suaw-our-organization-guide__item">
            <SuawParagraph
              text="<b>Global Impact:</b> By tracking our global impact, we're showing how our community is making a difference. From cafés, to public libraries, to universities around the world, our members gather to get writing done and support each other."
            />
          </li>
        </ul>
      </div>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-our-organization-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-our-organization-intro {
  font-family: Roboto;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--sem-color-neutral-dark);
}
.suaw-our-organization-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
//list
.suaw-our-organization-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
